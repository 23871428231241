import { differenceInMinutes } from 'date-fns'

import { getIdToken, getUserData } from '../services/aws'

import { capitalize, hashObject } from '../utils'

import { StaticS3Client } from './s3'

import { BUCKET_NAME, BUILD_ENV } from '../config/env'

const toDateISOString = date => {
  return date.toISOString().split('T')[0]
}

export const MIN_DELAY_TO_REQUEST_RECENT_SEARCHES_IN_MINUTES = 15
const RECENT_SEARCHES_KEY = 'recentSearches'

export default class RecentSearches {
  static getMetadata () {
    const { userId, scope } = RecentSearches._getFromLocalStorage()
    return { userId, scope }
  }

  static getMyRecentSearches ({ username }) {
    const events = RecentSearches.getRecentSearches()

    return events.filter(item => item.user === username)
  }

  static getRecentSearches () {
    const dataFromLocalStorage = RecentSearches._getDataFromLocalStorage()

    return dataFromLocalStorage.sort((a, b) => b.timestamp - a.timestamp)
  }

  static async updateRecentSearches () {
    const now = new Date()

    const { isUpdated, data } = await RecentSearches._fetchRecentSearches()

    const { username, scope, tenant } = await getUserData()

    if (isUpdated) {
      RecentSearches._storeRecentSearches({
        updatedAt: now,
        data,
        userId: username,
        tenant,
        scope
      })
    }

    return data
  }

  static async put ({ id, document, name }) {
    const { username, scope, tenant, email } = await getUserData()

    const now = new Date()
    const timestamp = now.toISOString()

    const { data } = RecentSearches._getFromLocalStorage()
    const updatedAt = RecentSearches.getUpdatedAtFromLocalStorage()
    const newData = [
      ...data,
      {
        id,
        timestamp,
        document,
        user: username,
        origin: 'custom',
        tenant,
        user_email: email,
        name
      }
    ]

    localStorage.setItem(
      RECENT_SEARCHES_KEY,
      JSON.stringify({ updatedAt, data: newData, userId: username, scope })
    )

    return {
      id,
      timestamp,
      document,
      scope,
      userId: username,
      origin: 'custom'
    }
  }

  static async _fetchRecentSearches () {
    const now = new Date()

    const dataFromLocalStorage = RecentSearches._getDataFromLocalStorage()
    const updatedAt = RecentSearches.getUpdatedAtFromLocalStorage()

    const isUpdateRequired =
      !updatedAt ||
      differenceInMinutes(now, updatedAt) >
        MIN_DELAY_TO_REQUEST_RECENT_SEARCHES_IN_MINUTES

    let recentSearches = [...dataFromLocalStorage]
    if (isUpdateRequired) {
      const dataFromApi = await RecentSearches._getFromApi()
      const idsFromApi = dataFromApi.map(item => item.id)

      const customItems = dataFromLocalStorage.filter(
        item => item.origin === 'custom' && !idsFromApi.includes(item.id)
      )

      recentSearches = [...customItems, ...dataFromApi]
    }

    const documentsWithName = new Map(
      recentSearches
        .filter(item => item.name?.length > 0)
        .map(item => [item.document, item.name])
    )

    const mappedRecentSearches = recentSearches.map(item => {
      const name = documentsWithName.get(item.document)
      return { ...item, name }
    })

    return {
      isUpdated: isUpdateRequired,
      data: mappedRecentSearches
    }
  }

  static resetLocalStorage () {
    localStorage.removeItem(RECENT_SEARCHES_KEY)
  }

  static _getDataFromLocalStorage () {
    const { data } = RecentSearches._getFromLocalStorage()
    return data
  }

  static getUpdatedAtFromLocalStorage () {
    const { updatedAt } = RecentSearches._getFromLocalStorage()
    return typeof updatedAt === 'string' ? new Date(updatedAt) : updatedAt
  }

  static _storeRecentSearches (jsonData) {
    const rawData = JSON.stringify(jsonData)
    localStorage.setItem(RECENT_SEARCHES_KEY, rawData)
  }

  static _getFromLocalStorage () {
    const rawData =
      localStorage.getItem(RECENT_SEARCHES_KEY) ||
      '{"updatedAt": null, "data":[], "userId": null, "scope": null}'
    const jsonData = JSON.parse(rawData)
    const arrayData = jsonData.data.map(item => ({
      ...item,
      timestamp: new Date(item.timestamp)
    }))

    return { ...jsonData, data: arrayData }
  }

  static async _getFromApi () {
    const accessToken = await getIdToken()
    const s3Client = await StaticS3Client.getInstance({ accessToken })
    const { scope, tenant } = await getUserData()

    const dataFromApi = await s3Client.readFile({
      bucket: BUCKET_NAME,
      key: `logs-boanota/scope=${scope}/service=boanota/resource=LogQuery-BoaNota-Function-${capitalize(
        BUILD_ENV
      )}/rolling/json`,
      responseContentType: 'application/json'
    })
    return dataFromApi
      .filter(item => item.scope === scope && item.tenant === tenant)
      .map(item => {
        const timestamp = new Date(item.timestamp)
        return {
          ...item,
          timestamp: timestamp,
          origin: 'api',
          id:
            item.id ??
            hashObject({
              user: item.user,
              document: item.document,
              date: toDateISOString(timestamp)
            })
        }
      })
  }
}
