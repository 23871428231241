import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'

import { CustomWindow, Table } from '../../../../../components'
import { onSortDocument } from '../../../../../components/Table/sort'
import mapWithRelationship from '../../../../../lib/detectRelationship'
import { selectRelateds } from '../../../../../redux/selectors/relateds'
import {
  formatDocument,
  hashObject,
  isCypheredDocument,
  isPending,
  onlyNumbers
} from '../../../../../utils'

const { SearchBar } = Search

export default function ExpulsoesDaAdministracaoFederal ({
  data,
  error,
  loaded
}) {
  const relateds = useSelector(selectRelateds)

  const name = 'bandeiraAmarela.expulsoesDaAdministracaoFederal'
  const title = 'Expulsões da Administração Federal'

  if (isPending({ error, loaded })) {
    return (
      <CustomWindow title={title} name={name}>
        <div />
      </CustomWindow>
    )
  }

  if (error) {
    return (
      <CustomWindow title={title} name={name} defaultOpen showNote>
        <div>
          Ops! Aconteceu um erro ao carregar expulsões da administração federal
        </div>
      </CustomWindow>
    )
  }

  return (
    <CustomWindow title={title} name={name} showNote>
      <ToolkitProvider
        keyField='id'
        search
        columns={[
          { dataField: 'nome', text: 'Nome', sort: true },
          {
            dataField: 'relationship',
            text: 'Grau',
            sort: true
          },
          {
            dataField: 'cpf',
            text: 'CPF',
            searchable: false,
            sortFunc: onSortDocument,
            sort: true
          },
          {
            dataField: 'tipoPunicao',
            text: 'Tipo de Punição'
          },
          { dataField: 'rawDocument', text: '', hidden: true },
          {
            dataField: 'orgaoLotacao',
            text: 'Órgão de Lotação',
            sort: true
          },
          {
            dataField: 'cargoEfetivo',
            text: 'Cargo Efetivo',
            sort: true
          },
          {
            dataField: 'funcaoConfianca',
            text: 'Função de Confiança',
            sort: true
          },
          {
            dataField: 'numeroPortariaPunicao',
            text: 'Número da Portaria de Punição'
          },
          {
            dataField: 'dataPublicacao',
            text: 'Data de Publicação'
          },
          {
            dataField: 'pagina',
            text: 'Página'
          },
          {
            dataField: 'secao',
            text: 'Seção'
          },
          {
            dataField: 'numeroProcessoAdministrativo',
            text: 'Número do Processo Administrativo'
          }
        ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
        data={mapWithRelationship({
          array: data || [],
          documentKey: 'cpf',
          nameKey: 'nome',
          relateds
        }).map(item => {
          const id = hashObject(item)
          return {
            ...item,
            id,
            rawDocument: item.cpf,
            cpf: isCypheredDocument(item.cpf) ? (
              item.cpf
            ) : (
              <a
                href={`?documento=${onlyNumbers(item.cpf)}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {formatDocument(item.cpf)}
              </a>
            )
          }
        })}
      >
        {props => (
          <>
            <div className='row'>
              <div className='col-12 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Table enablePagination {...props.baseProps} />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </CustomWindow>
  )
}
