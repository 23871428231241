import React from 'react'
import { Route } from 'react-router-dom'

import { Header, Main, Footer, Sidebar, SubHeader } from '../../components'

export default function DossieRoute ({
  component: Component,
  withSidebar,
  withSubHeader,
  hideSearch,
  hideTitle,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props => (
        <WrappedComponent
          withSidebar={withSidebar}
          withSubHeader={withSubHeader}
          hideSearch={hideSearch}
          hideTitle={hideTitle}
        >
          <Component {...props} />
        </WrappedComponent>
      )}
    />
  )
}

const WrappedComponent = ({
  children,
  withSidebar,
  withSubHeader,
  hideSearch,
  hideTitle
}) => {
  return (
    <>
      <Header />
      <Main>
        {withSubHeader && (
          <SubHeader hideSearch={hideSearch} hideTitle={hideTitle} />
        )}
        <div className='d-flex flex-grow-1 flex-row my-4'>
          {withSidebar && <Sidebar />}
          <main className='d-flex flex-grow-1'>{children}</main>
        </div>
      </Main>
      <Footer />
    </>
  )
}

export const DossieRouteWithSidebarAndSubHeader = ({ component, ...rest }) => {
  return (
    <DossieRoute
      {...rest}
      withSidebar
      withSubHeader
      hideSearch={false}
      component={component}
      hideTitle={false}
    />
  )
}
