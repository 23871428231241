import React from 'react'
import { Route } from 'react-router-dom'

import Header from './Header'
import Main from './Main'
import Footer from './Footer'

export const HubRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <>
          <Header />
          <Main>
            <div className='d-flex flex-grow-1 flex-row my-4'>
              <main className='d-flex flex-grow-1'>
                <Component {...props} />
              </main>
            </div>
          </Main>
          <Footer />
        </>
      )}
    />
  )
}
