import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'

import { CustomWindow, Table } from '../../../../../components'
import mapWithRelationship from '../../../../../lib/detectRelationship'
import { selectRelateds } from '../../../../../redux/selectors/relateds'
import { isPending, hashObject } from '../../../../../utils'

const { SearchBar } = Search

export default function CadastroExpulsoes ({ data, error, loaded }) {
  const relateds = useSelector(selectRelateds)

  const name = 'bandeiraAmarela.cadastroExpulsoes'
  const title = 'Cadastro de expulsões'

  if (isPending({ error, loaded })) {
    return (
      <CustomWindow title={title} name={name}>
        <div />
      </CustomWindow>
    )
  }

  if (error) {
    return (
      <CustomWindow title={title} name={name} defaultOpen showNote>
        <div>Ops! Aconteceu um erro ao carregar os cadastros de expulsões</div>
      </CustomWindow>
    )
  }

  return (
    <CustomWindow title={title} name={name} showNote>
      <ToolkitProvider
        keyField='id'
        search
        columns={[
          { dataField: 'nome', text: 'Nome' },
          {
            dataField: 'relationship',
            text: 'Grau'
          },
          { dataField: 'dataPublicacao', text: 'Data' },
          { dataField: 'matricula', text: 'Matrícula' },
          { dataField: 'numeroProcesso', text: 'Processo' },
          { dataField: 'cargo', text: 'Cargo' },
          { dataField: 'orgao', text: 'Órgão' },
          { dataField: 'punicao', text: 'Punição' },
          { dataField: 'motivo', text: 'Motivo' },
          { dataField: 'fundamentoLegal', text: 'Fundamento legal' }
        ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
        data={mapWithRelationship({
          array: data || [],
          documentKey: 'cpfCnpjPessoaApreensao',
          nameKey: 'nome',
          relateds
        }).map(item => {
          const id = hashObject(item)
          return {
            ...item,
            id
          }
        })}
      >
        {props => (
          <>
            <div className='row'>
              <div className='col-12 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Table enablePagination {...props.baseProps} />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </CustomWindow>
  )
}
