import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { differenceInYears } from 'date-fns/esm'

import * as styles from './style.module.scss'

import questionnaires from '../../../../../../questionnaires/pld-sulbrasil'
import { selectMinData } from '../../../../../../redux/selectors/dadosBasicos'

import { PROGRESS_TYPES } from '../../../../../../lib/progressStatus'

import { useDadosBasicos, usePep } from '../../../../../../utils/hooks'
import { hashObject } from '../../../../../../utils'

import { Loading } from '../../../../../../components'
import { setScorePldSulBrasil } from '../../../../../../redux/store/sulbrasil'

const formatBool = bool => {
  if (typeof bool !== 'boolean') {
    return undefined
  }

  return bool ? 'sim' : 'nao'
}

const computeScore = ({ values, defaultValues }) => {
  const mergedValues = Object.entries(values).map(([key, value]) => [
    key,
    value ?? defaultValues[key]
  ])

  const array = mergedValues.sort((a, b) => {
    const aIndex = questionnaires.findIndex(item => item.id === a[0])
    const bIndex = questionnaires.findIndex(item => item.id === b[0])
    return aIndex - bIndex
  })
  const arrayValues = array
    .map(([, value]) => value)
    .filter(value => value !== undefined)

  const first13Questions = arrayValues.slice(0, 13)

  if (arrayValues.length !== questionnaires.length) {
    return 'Informações insuficientes para o cálculo de risco'
  }

  if (first13Questions.includes('sim')) {
    return 'Risco Elevado'
  }

  if (arrayValues.every(i => i === 'nao')) {
    return 'Risco Baixo'
  }

  return 'Informações insuficientes para o cálculo de risco'
}

export default function SulbrasilScorePld () {
  const { targetDocument, isCPF } = useSelector(selectMinData)
  const { data: dadosBasicos, status: statusDadosBasicos } = useDadosBasicos()
  const { data: pep, status: statusPep } = usePep()
  const form = useSelector(state => state.sulbrasil.formulario.scorePld.form)
  const dispatch = useDispatch()

  const values = useMemo(() => {
    return (form || []).reduce((obj, item) => {
      obj[item.id] = item.value
      return obj
    }, {})
  }, [form])

  const defaultValues = useMemo(() => {
    if (isCPF) {
      return {
        quadroSocietarioAlterado: undefined,
        quadroSocietarioPPE: undefined,
        empresasRelacionadas: undefined,
        lavagemDinheiro: undefined,
        quadroSocietarioPessoaJuridica: undefined,
        empresasConstituidas: undefined
      }
    }

    const today = new Date()
    const socios = dadosBasicos?.socios?.receitaFederal || []
    const maxDataEntrada = new Date(
      Math.max.apply(
        null,
        socios.map(socio => new Date(socio.dataEntradaSociedade))
      )
    )
    const dataAberturaEmpresa = new Date(
      dadosBasicos?.dadosBasicos?.datas.dataInicioAtividade || Infinity
    )

    const perguntaMinExistencia = formatBool(
      differenceInYears(today, maxDataEntrada) <= 1 ||
        differenceInYears(today, dataAberturaEmpresa) <= 1
    )

    const perguntaEmpresaSocia = formatBool(
      socios.filter(item => item.identificadorSocio === 'PESSOA JURÍDICA')
        .length > 0
    )

    const perguntaPep = formatBool(pep.filter(item => item.isPep).length > 0)

    const perguntaEmpresaConstituidaEntre1e3Anos = formatBool(
      differenceInYears(today, dataAberturaEmpresa) >= 1 &&
        differenceInYears(today, dataAberturaEmpresa) <= 3
    )

    return {
      quadroSocietarioAlterado: perguntaMinExistencia,
      quadroSocietarioPPE: perguntaPep,
      quadroSocietarioPessoaJuridica: perguntaEmpresaSocia,
      empresasConstituidas: perguntaEmpresaConstituidaEntre1e3Anos
    }
  }, [dadosBasicos, pep, isCPF])

  const onChange = nextObj => {
    const nextValues = { ...values, ...nextObj }

    const formValues = questionnaires.reduce((obj, { id }) => {
      obj[id] = nextValues[id] ?? defaultValues[id]
      return obj
    }, {})

    const result = computeScore({ values: formValues, defaultValues })
    dispatch(setScorePldSulBrasil({ form: formValues, result }))
  }

  const result = useMemo(() => {
    return computeScore({ values, defaultValues })
  }, [values, defaultValues])

  if (!targetDocument) {
    return <div />
  }

  if (
    [statusDadosBasicos, statusPep].some(
      status => status === PROGRESS_TYPES.PENDING
    )
  ) {
    return <Loading />
  }

  return (
    <>
      <div>
        {questionnaires.map((questionnaire, questionIndex) => {
          const name = questionnaire.id
          const questionKey = hashObject({
            key: 'pld',
            questionnaire,
            questionIndex
          })

          return (
            <div className='my-4' key={questionKey}>
              <div className={styles.question}>
                {questionIndex + 1} - {questionnaire.question}
              </div>
              <div className='d-flex gap-4 mt-2'>
                {questionnaire.answers.map((answer, answerIndex) => {
                  const id = hashObject({
                    questionKey,
                    answer,
                    questionIndex,
                    answerIndex
                  })
                  return (
                    <div className='form-check' key={id}>
                      <input
                        className='form-check-input'
                        type='radio'
                        name={id}
                        id={id}
                        value={answer.value}
                        onChange={event => {
                          if (onChange) {
                            onChange({
                              [name]: event.target.value
                            })
                          }
                        }}
                        checked={
                          (values?.[name] ?? defaultValues[name]) ===
                          answer.value
                        }
                      />
                      <label className='form-check-label' htmlFor={id}>
                        {answer.text}
                      </label>
                    </div>
                  )
                })}
              </div>
              <hr />
            </div>
          )
        })}
      </div>
      <div>
        <div className='h4 text-center'>{result}</div>
      </div>
    </>
  )
}
