import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'

import { CustomWindow, Table } from '../../../../../components'
import mapWithRelationship from '../../../../../lib/detectRelationship'
import { selectRelateds } from '../../../../../redux/selectors/relateds'
import {
  formatDocument,
  hashObject,
  isCypheredDocument,
  isPending,
  onlyNumbers
} from '../../../../../utils'

const { SearchBar } = Search

export default function EntidadesPrivadasSemFinsLucrativos ({
  data,
  error,
  loaded
}) {
  const relateds = useSelector(selectRelateds)

  const name = 'bandeiraAmarela.cepim'
  const title = 'Entidades Privadas Sem Fins Lucrativos'

  if (isPending({ error, loaded })) {
    return (
      <CustomWindow title={title} name={name}>
        <div />
      </CustomWindow>
    )
  }

  if (error) {
    return (
      <CustomWindow title={title} name={name} defaultOpen showNote>
        <div>
          Ops! Aconteceu um erro ao carregar Entidades Privadas Sem Fins
          Lucrativos
        </div>
      </CustomWindow>
    )
  }

  return (
    <CustomWindow title={title} name={name} showNote>
      <ToolkitProvider
        keyField='id'
        search
        columns={[
          { dataField: 'nomeEntidade', text: 'Nome' },
          {
            dataField: 'relationship',
            text: 'Grau'
          },
          { dataField: 'document', text: 'CPF / CNPJ', searchable: false },
          { dataField: 'rawDocument', text: '', hidden: true },
          { dataField: 'numeroConvenio', text: 'Número do convênio' },
          { dataField: 'orgaoConcedente', text: 'Órgão concedente' },
          { dataField: 'motivoImpedimento', text: 'Motivo do impedimento' }
        ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
        data={mapWithRelationship({
          array: data || [],
          documentKey: 'cnpjEntidade',
          nameKey: 'nomeEntidade',
          relateds
        }).map(item => {
          const id = hashObject(item)
          return {
            ...item,
            id,
            rawDocument: item.cpfCnpjInfrator,
            document: isCypheredDocument(item.cnpjEntidade) ? (
              item.cnpjEntidade
            ) : (
              <a
                href={`?documento=${onlyNumbers(item.cnpjEntidade)}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {formatDocument(item.cnpjEntidade)}
              </a>
            )
          }
        })}
      >
        {props => (
          <>
            <div className='row'>
              <div className='col-12 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Table enablePagination {...props.baseProps} />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </CustomWindow>
  )
}
