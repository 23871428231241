import {
  getDadosCadastrais,
  getGrafo,
  getGrafo2,
  consultarCorreioEletronico,
  consultarDominioEmail,
  consultarEndereco,
  consultarTelefone,
  listarMatches
} from './grupoSocietario'
import { consultarImagens } from './googleMaps'
import { startGenerateDocumento } from './docx'
import { createQueue } from './sqs'
import { startRootStepFunction, updateDocumentStatus } from './rootStep'
import {
  getComment,
  lockComment,
  moveComment,
  saveComment,
  unlockComment
} from './comments'
import { consultarSerasa } from './serasa'
import { updateRating } from './rating'
import { computePldScore, getPLDQuestionnaire } from './questionnaires'

const API = {
  rating: {
    update: updateRating
  },
  serasa: {
    consultar: consultarSerasa
  },
  comments: {
    save: saveComment,
    get: getComment,
    lock: lockComment,
    unlock: unlockComment,
    move: moveComment
  },
  receitaFederal: {
    matches: {
      consultarCorreioEletronico,
      consultarDominioEmail,
      consultarEndereco,
      consultarTelefone
    }
  },
  googleMaps: {
    consultar: consultarImagens
  },
  docx: {
    startGenerateDocumento
  },
  sqs: {
    create: createQueue
  },
  rootStep: {
    start: startRootStepFunction,
    updateStatus: updateDocumentStatus
  },
  questionnaires: {
    pld: {
      get: getPLDQuestionnaire,
      compute: computePldScore
    }
  },
  grupoSocietario: {
    getDadosCadastrais,
    getGrafo,
    getGrafo2,
    matches: {
      consultarCorreioEletronico,
      consultarDominioEmail,
      consultarEndereco,
      consultarTelefone,
      listar: listarMatches
    }
  }
}

export default API
