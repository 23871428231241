import axios from 'axios'

import { BUILD_ENV, QUESTIONNAIRES_BASE_URL, SCOPE } from '../../config/env'

import { getAccessToken, getIdToken } from '../aws'

import { StaticLambdaClient } from '../../lib/lambda'

import { capitalize } from '../../utils'

import sulbrasilQuestionnaire from '../../questionnaires/pld-sulbrasil'

export const getPLDQuestionnaire = async ({ globalStartTime, document }) => {
  const cleanDocument = document.replace(/\D/g, '')
  const useSulbrasilQuestionnaire = ['sulbrasil'].includes(SCOPE)
  const useTentoscapQuestionnaire = ['tentoscap'].includes(SCOPE)

  if (!useSulbrasilQuestionnaire && !useTentoscapQuestionnaire) {
    throw new Error('Questionnaire not found')
  }

  if (useSulbrasilQuestionnaire) {
    return sulbrasilQuestionnaire
  }

  const accessToken = await getAccessToken()
  const idToken = await getIdToken()

  const lambdaClient = await StaticLambdaClient.getInstance({ idToken })

  const response = await lambdaClient.invokeFunction({
    functionName: `Questionario-AutomaticPLD-Function-${capitalize(BUILD_ENV)}`,
    body: {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        product: 'boanota',
        document: cleanDocument,
        global_time: globalStartTime
      })
    }
  })

  const body = JSON.parse(response.body)
  const isOk = response.statusCode >= 200 && response.statusCode < 300

  if (!isOk) {
    throw new Error(body.message)
  }

  return body
}

export const computePldScore = async answers => {
  const accessToken = await getAccessToken()

  const { data } = await axios.post(
    `${QUESTIONNAIRES_BASE_URL}/calculo-risco`,
    answers,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
  return data
}
