const questionnaires = [
  {
    question:
      'Quadro societário alterado ou empresas constituídas em prazo inferior a 1 ano.',
    id: 'quadroSocietarioAlterado',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'É representada de forma contumaz por terceiros, sem vínculo societário ou empregatício.',
    id: 'representadaContumaz',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'A carteira de clientes é composta majoritariamente por entes da administração pública.',
    id: 'carteiraClientes',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Mais de 70% das operações não são creditadas na sua própria conta corrente.',
    id: 'operacoesNaoCreditadas',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'O quadro societário tem participação de pessoas politicamente expostas - PPE ou a elas relacionadas.',
    id: 'quadroSocietarioPPE',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Empresa de qualquer forma relacionada a pessoa enquadrada em qualquer das condições previstas no art. 1º da Resolução do COAF no. 15, de 28.3.2007. (Bin Laden)',
    id: 'empresasRelacionadas',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Representada ou tem participação acionária de pessoa domiciliada em jurisdição com deficiências estratégicas de prevenção à lavagem de dinheiro e ao financiamento do terrorismo ou região considerada de tributação favorecida.',
    id: 'lavagemDinheiro',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Operações de valores incompatíveis com o porte e/ou balanço financeiro do cedente ou do sacado, conforme as informações obtidas quando do cadastro?',
    id: 'operacoesValoresIncompativeis',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Operações que indiquem a realização de negócio entre o cedente e empresa a ele relacionada (intercompany)?',
    id: 'operacoesIndiquemNegocioIntercompany',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Operações cujos títulos demonstrem alteração atípica de volume e/ou frequência dos negócios entre o cedente e o sacado?',
    id: 'operacoesDemonstremAlteracaoAtipicaVolumeFrequenciaNegocios',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Operações que demonstrem a realização de negócios com sacados atípicos, como empresas de outros ramos e clientes desconhecidos?',
    id: 'operacoesDemonstremNegociosSacadosAtipicos',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question: 'Operações realizadas sem razão econômica aparente?',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Operações que demonstrem incompatibilidade do negócio realizado com a qualificação técnica do cedente?',
    id: 'operacoesDemonstremIncompatibilidadeNegocioQualificacaoTecnicaCedente',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  }
]

export default questionnaires
