import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import { EXCLUSIVE_CONTENTS, StaticScopeMetadata } from '../../lib/scope'

import DossieRoute, { DossieRouteWithSidebarAndSubHeader } from './DossieRoute'
import {
  DadosBasicos,
  Grupo,
  Endividamento,
  Juridico,
  Offshore,
  Operacionais,
  Patrimonio,
  RF,
  BandeiraAmarela,
  Formulario,
  MidiaDesabonadora,
  Historico
} from './pages'

export default function DossieRouter () {
  const isSubApp = StaticScopeMetadata.getAvailableApps().length > 1
  const hasPermission = StaticScopeMetadata.getAvailableApps().includes(
    EXCLUSIVE_CONTENTS.APPS.DOSSIER
  )
  const basePath = isSubApp ? '/dossie' : ''

  if (!hasPermission) {
    return <Redirect to='/' />
  }

  return (
    <Switch>
      <DossieRoute
        path={`${basePath}/historico`}
        component={Historico}
        exact
        withSubHeader
        hideSearch
        hideTitle
      />
      <DossieRouteWithSidebarAndSubHeader
        path={`${basePath}/dados-basicos`}
        component={DadosBasicos}
      />
      <DossieRouteWithSidebarAndSubHeader
        path={`${basePath}/grupo`}
        component={Grupo}
      />
      <DossieRouteWithSidebarAndSubHeader
        path={`${basePath}/patrimonio`}
        component={Patrimonio}
      />
      <DossieRouteWithSidebarAndSubHeader
        path={`${basePath}/pontos-atencao`}
        component={BandeiraAmarela}
      />
      <DossieRouteWithSidebarAndSubHeader
        path={`${basePath}/offshore`}
        component={Offshore}
      />
      <DossieRouteWithSidebarAndSubHeader
        path={`${basePath}/endividamento`}
        component={Endividamento}
      />
      <DossieRouteWithSidebarAndSubHeader
        path={`${basePath}/juridico`}
        component={Juridico}
      />
      <DossieRouteWithSidebarAndSubHeader
        path={`${basePath}/midia-desabonadora`}
        component={MidiaDesabonadora}
      />
      <DossieRouteWithSidebarAndSubHeader
        path={`${basePath}/operacionais`}
        component={Operacionais}
      />
      <DossieRouteWithSidebarAndSubHeader
        path={`${basePath}/rf`}
        component={RF}
      />
      {StaticScopeMetadata.getAvailablePages().includes(
        EXCLUSIVE_CONTENTS.PAGES.FORMULARIO
      ) && (
        <DossieRouteWithSidebarAndSubHeader
          path={`${basePath}/formulario`}
          component={Formulario}
        />
      )}
      <Redirect to={`${basePath}/dados-basicos`} />
    </Switch>
  )
}
