import React from 'react'

const DetailsPartes = props => {
  const { processo } = props
  const [partes, setPartes] = React.useState({
    ativa: [],
    neutro: [],
    passivo: [],
    ativo_adv: [],
    neutro_adv: [],
    passivo_adv: []
  })

  React.useEffect(() => {
    const ativaTemp = processo.filter(item => item.polaridade === 'ATIVO')
    const neutroTemp = processo.filter(item => item.polaridade === 'NEUTRO')
    const passivoTemp = processo.filter(item => item.polaridade === 'PASSIVO')

    const ativoAdv = ativaTemp.filter(item => item.tipo === 'ADVOGADO')
    const neutroAdv = neutroTemp.filter(item => item.tipo === 'ADVOGADO')
    const passivoAdv = passivoTemp.filter(item => item.tipo === 'ADVOGADO')

    setPartes(prev => ({
      ...prev,
      ativa: ativaTemp.filter(item => !ativoAdv.includes(item)),
      passivo: passivoTemp.filter(item => !passivoAdv.includes(item)),
      neutro: neutroTemp.filter(item => !neutroAdv.includes(item)),
      ativo_adv: ativoAdv,
      neutro_adv: neutroAdv,
      passivo_adv: passivoAdv
    }))
  }, [processo])

  return (
    <div className='row'>
      <div className='col-md-4 col-12'>
        {processo && (
          <>
            <p className='text-primary mt-4 mb-0' style={{ fontSize: 24 }}>
              Parte Ativa
            </p>
            <div className='parte-title'>Parte Ativa</div>

            <ul className='parte-content'>
              {partes &&
                partes.ativa &&
                partes.ativa.length > 0 &&
                partes.ativa.map((parte, key) => {
                  return (
                    <li className='parte-card parte-card-right' key={key}>
                      {parte.nome}
                    </li>
                  )
                })}
            </ul>

            <div className='parte-divisor' />

            <div className='parte-title'>Advogado - Parte Ativa</div>

            <ul className='parte-content'>
              {partes &&
                partes.ativo_adv &&
                partes.ativo_adv.length > 0 &&
                partes.ativo_adv.map((parte, key) => {
                  return (
                    <li className='parte-card parte-card-right' key={key}>
                      {parte.nome}
                    </li>
                  )
                })}
            </ul>
          </>
        )}
      </div>

      <div className='col-md-4 col-12'>
        {processo && (
          <>
            <p className='text-primary mt-4 mb-0' style={{ fontSize: 24 }}>
              Parte Neutra
            </p>
            <div className='parte-title'>Parte Neutra</div>

            <ul className='parte-content'>
              {partes &&
                partes.neutro &&
                partes.neutro.length > 0 &&
                partes.neutro.map((parte, key) => {
                  return (
                    <li className='parte-card parte-card-left' key={key}>
                      {parte.nome}
                    </li>
                  )
                })}
            </ul>

            <div className='parte-divisor' />

            <div className='parte-title'>Advogado - Parte Passivo</div>

            <ul className='parte-content'>
              {partes &&
                partes.neutro_adv &&
                partes.neutro_adv.length > 0 &&
                partes.neutro_adv.map((parte, key) => {
                  return (
                    <li className='parte-card parte-card-left' key={key}>
                      {parte.nome}
                    </li>
                  )
                })}
            </ul>
          </>
        )}
      </div>

      <div className='col-md-4 col-12'>
        {processo && (
          <>
            <p className='text-primary mt-4 mb-0' style={{ fontSize: 24 }}>
              Parte Passiva
            </p>
            <div className='parte-title'>Parte Passiva</div>

            <ul className='parte-content'>
              {partes &&
                partes.passivo &&
                partes.passivo.length > 0 &&
                partes.passivo.map((parte, key) => {
                  return (
                    <li className='parte-card parte-card-left' key={key}>
                      {parte.nome}
                    </li>
                  )
                })}
            </ul>

            <div className='parte-divisor' />

            <div className='parte-title'>Advogado - Parte Passivo</div>

            <ul className='parte-content'>
              {partes &&
                partes.passivo_adv &&
                partes.passivo_adv.length > 0 &&
                partes.passivo_adv.map((parte, key) => {
                  return (
                    <li className='parte-card parte-card-left' key={key}>
                      {parte.nome}
                    </li>
                  )
                })}
            </ul>
          </>
        )}
      </div>
    </div>
  )
}

export default DetailsPartes
