import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'

import { CustomWindow, Table } from '../../../../../components'
import mapWithRelationship from '../../../../../lib/detectRelationship'
import { selectRelateds } from '../../../../../redux/selectors/relateds'
import {
  formatDocument,
  isCypheredDocument,
  isPending,
  onlyNumbers,
  hashObject,
  formatCurrency
} from '../../../../../utils'

const { SearchBar } = Search

export default function TermoEmbargo ({ data, error, loaded }) {
  const relateds = useSelector(selectRelateds)

  const name = 'bandeiraAmarela.termoEmbargo'
  const title = 'Termo de Embargo'

  if (isPending({ error, loaded })) {
    return (
      <CustomWindow title={title} name={name}>
        <div />
      </CustomWindow>
    )
  }

  if (error) {
    return (
      <CustomWindow title={title} name={name} defaultOpen showNote>
        <div>Ops! Aconteceu um erro ao carregar os termos de embargo</div>
      </CustomWindow>
    )
  }

  return (
    <CustomWindow title={title} name={name} showNote>
      <ToolkitProvider
        keyField='id'
        search
        columns={[
          { dataField: 'nomeEmbargado', text: 'Nome' },
          {
            dataField: 'relationship',
            text: 'Grau'
          },
          { dataField: 'document', text: 'CPF / CNPJ', searchable: false },
          { dataField: 'rawDocument', text: '', hidden: true },
          { dataField: 'date', text: 'Data' },
          { dataField: 'tipoArea', text: 'Tipo' },
          { dataField: 'numProcesso', text: 'Processo' },
          { dataField: 'local', text: 'Local' },
          { dataField: 'desTad', text: 'Circunstâncias do embargo' }
        ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
        data={mapWithRelationship({
          array: data || [],
          documentKey: 'cpfCnpjEmbargado',
          nameKey: 'nomeEmbargado',
          relateds
        }).map(item => {
          const id = hashObject(item)
          return {
            ...item,
            id,
            rawDocument: item.cpfCnpjEmbargado,
            document: isCypheredDocument(item.cpfCnpjEmbargado) ? (
              item.cpfCnpjEmbargado
            ) : (
              <a
                href={`?documento=${onlyNumbers(item.cpfCnpjEmbargado)}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {formatDocument(item.cpfCnpjEmbargado)}
              </a>
            ),
            date: item.datEmbargo.includes(':')
              ? item.datEmbargo.split(' ')[0]
              : item.datEmbargo,
            local: `${item.desLocalizacao} - ${item.municipio}/${item.uf}`,
            valor: formatCurrency(parseFloat(item.valorTad))
          }
        })}
      >
        {props => (
          <>
            <div className='row'>
              <div className='col-12 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Table enablePagination {...props.baseProps} />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </CustomWindow>
  )
}
