import questionarioPld from '../../questionnaires/pld-sulbrasil'

// Action Types
export const Types = {
  GERAR_RELATORIO: 'SULBRASIL/GERAR_RELATORIO',
  SET_FORMULARIO_SALVO: 'SULBRASIL/SET_FORMULARIO_SALVO',
  SET_SCORE_PLD: 'SULBRASIL/SET_SCORE_PLD',
  SET_SCORE_PLD_TENTOS: 'SULBRASIL/SET_SCORE_PLD_TENTOS',
  SET_SCORE_PLD_FORM: 'SULBRASIL/SET_SCORE_PLD_FORM',
  SET_SCORE_COMPORTAMENTAL: 'SULBRASIL/SET_SCORE_COMPORTAMENTAL',
  SET_SCORE_PROSPECCAO: 'SULBRASIL/SET_SCORE_PROSPECCAO',
  SET_FATURAMENTO: 'SULBRASIL/SET_FATURAMENTO',
  SET_DADOS_OPERACIONAIS: 'SULBRASIL/SET_DADOS_OPERACIONAIS',
  SET_IMAGENS: 'SULBRASIL/SET_IMAGENS',
  SET_STREET_VIEW: 'SULBRASIL/SET_STREET_VIEW',
  SET_DEFAULT_STREET_VIEW: 'SULBRASIL/SET_DEFAULT_STREET_VIEW',
  RESET: 'SULBRASIL/RESET'
}

// Reducer
const initialState = {
  formularioSalvo: true,
  formScorePld: [],
  formulario: {
    scorePld: {
      form: [],
      result: undefined
    },
    dadosOperacionais: {},
    faturamento: [],
    geolocation: {
      lat: undefined,
      lng: undefined
    },
    imagens: {
      marca: undefined,
      produto: undefined,
      streetView: undefined,
      defaultStreetView: undefined
    }
  }
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_FORMULARIO_SALVO: {
      return {
        ...state,
        formularioSalvo: action.payload
      }
    }
    case Types.SET_SCORE_PLD_FORM: {
      return {
        ...state,
        formScorePld: action.payload
      }
    }
    case Types.SET_DADOS_OPERACIONAIS: {
      return {
        ...state,
        formulario: {
          ...state.formulario,
          dadosOperacionais: action.payload
        }
      }
    }
    case Types.SET_FATURAMENTO: {
      return {
        ...state,
        formulario: {
          ...state.formulario,
          faturamento: action.payload
        }
      }
    }
    case Types.SET_SCORE_PLD: {
      return {
        ...state,
        formulario: {
          ...state.formulario,
          scorePld: { ...state.formulario.scorePld, ...action.payload }
        }
      }
    }
    case Types.SET_SCORE_PLD_TENTOS: {
      const { form, ...payload } = action.payload
      const prevData = (state.formulario.scorePld?.form || []).reduce(
        (obj, { id, value, description, detectedBy }) => {
          obj[id] = { value, description, detectedBy }
          return obj
        },
        {}
      )

      const mappedFormData = Object.entries(form)
        .map(([id, { value, description, detectedBy }]) => {
          const {
            value: prevValue,
            description: prevDescription,
            detectedBy: prevDetectedBy
          } = prevData[id] ?? {}
          return {
            id,
            key: questionarioPld.findIndex(question => question.id === id) + 1,
            value: value ?? prevValue,
            description: description ?? prevDescription,
            detectedBy: detectedBy ?? prevDetectedBy
          }
        })
        .filter(question => question.id !== undefined)

      return {
        ...state,
        formulario: {
          ...state.formulario,
          scorePld: {
            ...state.formulario.scorePld,
            ...payload,
            form: mappedFormData
          }
        }
      }
    }
    case Types.SET_IMAGENS: {
      return {
        ...state,
        formulario: {
          ...state.formulario,
          imagens: {
            ...state.formulario.imagens,
            ...action.payload
          }
        }
      }
    }
    case Types.SET_DEFAULT_STREET_VIEW: {
      return {
        ...state,
        formulario: {
          ...state.formulario,
          geolocation: {
            lat: action.payload.geolocation.lat,
            lng: action.payload.geolocation.lng
          },
          imagens: {
            ...state.formulario.imagens,
            defaultStreetView: action.payload.images
          }
        }
      }
    }
    case Types.SET_STREET_VIEW: {
      return {
        ...state,
        formulario: {
          ...state.formulario,
          imagens: {
            ...state.formulario.imagens,
            streetView: action.payload
          }
        }
      }
    }
    case Types.RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function setScorePldSulBrasil ({ form: formData, result }) {
  const mappedFormData = Object.entries(formData)
    .map(([id, questionValue]) => ({
      id,
      key: questionarioPld.findIndex(question => question.id === id) + 1,
      value: questionValue
    }))
    .filter(question => question.id !== undefined)

  return {
    type: Types.SET_SCORE_PLD,
    payload: { form: mappedFormData, result }
  }
}

export function setScorePldTentos ({ form, result }) {
  return {
    type: Types.SET_SCORE_PLD_TENTOS,
    payload: { form, result }
  }
}

export function setScorePldResultSulBrasil ({ result }) {
  return {
    type: Types.SET_SCORE_PLD,
    payload: { result }
  }
}

export function setScorePldFormSulbrasil (data) {
  return {
    type: Types.SET_SCORE_PLD_FORM,
    payload: data
  }
}

export function setFaturamentoSulBrasil (data) {
  return {
    type: Types.SET_FATURAMENTO,
    payload: data
  }
}

export function setDadosOperacionaisSulBrasil (data) {
  return {
    type: Types.SET_DADOS_OPERACIONAIS,
    payload: data
  }
}

export function setImagensSulBrasil (data) {
  return {
    type: Types.SET_IMAGENS,
    payload: data
  }
}

export function setDefaultStreetViewImageSulBrasil ({ images, geolocation }) {
  return {
    type: Types.SET_DEFAULT_STREET_VIEW,
    payload: { images, geolocation }
  }
}

export function setStreetViewImageSulBrasil (data) {
  return {
    type: Types.SET_STREET_VIEW,
    payload: data
  }
}

export function setFormularioSalvoSulBrasil (data) {
  return {
    type: Types.SET_FORMULARIO_SALVO,
    payload: data
  }
}

export function resetSulBrasil () {
  return {
    type: Types.RESET
  }
}

export function gerarRelatorioSulBrasil () {
  return {
    type: Types.GERAR_RELATORIO
  }
}
