import React from 'react'
import { useHistory } from 'react-router-dom'

import { AiFillFund } from 'react-icons/ai'
import { CgFileDocument } from 'react-icons/cg'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import { HiOutlineDocumentReport } from 'react-icons/hi'

import { PUBLIC_ATRIO_URL } from '../../config/env'

import { EXCLUSIVE_CONTENTS, StaticScopeMetadata } from '../../lib/scope'

const Hub = () => {
  return (
    <div className='container'>
      <div className='row justify-content-center'>
        {StaticScopeMetadata.getAvailableApps().includes(
          EXCLUSIVE_CONTENTS.APPS.DOSSIER
        ) && (
          <div className='col-3'>
            <HubCard
              title='Dossiê'
              description='No dossiê você consegue ver os processos, dívidas, patrimônios, embargos, infrações, offshores, PEP e muito mais.'
              icon={CgFileDocument}
              location='/dossie'
            />
          </div>
        )}
        {StaticScopeMetadata.getAvailableApps().includes(
          EXCLUSIVE_CONTENTS.APPS.INVESTMENT_FUND_ANALYSIS
        ) && (
          <div className='col-3'>
            <HubCard
              title='Análise dos fundos'
              description='Na análise dos fundos você pode consultar Sacado e Cedente.'
              icon={AiFillFund}
              location='/analise-fundo'
            />
          </div>
        )}
        {StaticScopeMetadata.getAvailableApps().includes(
          EXCLUSIVE_CONTENTS.APPS.CNAB
        ) && (
          <div className='col-3'>
            <HubCard
              title='Aprovação do CNAB'
              description='Aqui você pode fazer o upload de arquivos CNAB para aprovação'
              icon={FaFileInvoiceDollar}
              location='/cnab'
            />
          </div>
        )}
        {StaticScopeMetadata.getAvailableApps().includes(
          EXCLUSIVE_CONTENTS.APPS.ATRIO_PUBLIC_REPORT
        ) && (
          <div className='col-3'>
            <HubCard
              title='Carteira, Estoque e TLB'
              description='Aqui você poderá fazer o download dos dados de carteira, estoque e TLB'
              icon={HiOutlineDocumentReport}
              location={PUBLIC_ATRIO_URL}
              external
            />
          </div>
        )}
      </div>
    </div>
  )
}

const HubCard = ({ location, icon, title, description, external }) => {
  const history = useHistory()
  const Icon = icon

  return (
    <button
      className='card bg-primary text-white text-center p-3 h-100'
      onClick={() => {
        if (location) {
          if (external) {
            window.open(location, '_blank')
          } else {
            history.push(location)
          }
        }
      }}
    >
      <div className='card-title position-relative'>
        <div className='position-absolute left-0'>
          <Icon size={40} />
        </div>
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ minHeight: 40 }}
        >
          <h5 className='ml-5 ml-lg-0' style={{ maxWidth: 100 }}>
            {title}
          </h5>
        </div>
      </div>
      <div className='card-body p-0'>
        <small>{description}</small>
      </div>
    </button>
  )
}

export default Hub
