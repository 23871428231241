import axios from 'axios'

import { getIdToken } from '../../utils'

import { ROOT_STATE_MACHINE_BASE_URL } from '../../config/env'

export const updateRating = async ({ document, executionDate }) => {
  const url = `${ROOT_STATE_MACHINE_BASE_URL}/boanota/rating`

  const token = await getIdToken()

  const payload = {
    document,
    execution_date: executionDate
  }

  await axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
