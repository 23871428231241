import React from 'react'

import { formatDate } from './../utils'

const DetailsProcesso = props => {
  const { detalhes } = props

  return (
    <div>
      <div className='juridico-detalhes-card'>
        <div className='juridico-detalhes-label'>Foro / câmara</div>
        <div className='juridico-detalhes-value'>{detalhes?.comarca}</div>
      </div>

      <div className='juridico-detalhes-card'>
        <div className='juridico-detalhes-label'>Data de Início</div>
        <div className='juridico-detalhes-value'>
          {detalhes?.dataInicio
            ? formatDate(new Date(detalhes.dataInicio))
            : '-'}
        </div>
      </div>

      <div className='juridico-detalhes-card'>
        <div className='juridico-detalhes-label'>Data de Fim</div>
        <div className='juridico-detalhes-value'>
          {detalhes?.dataFim ? formatDate(new Date(detalhes.dataFim)) : '-'}
        </div>
      </div>

      <div className='juridico-detalhes-card'>
        <div className='juridico-detalhes-label'>Status Processual</div>
        <div className='juridico-detalhes-value'>
          {detalhes?.statusProcessual}
        </div>
      </div>
    </div>
  )
}

export default DetailsProcesso
