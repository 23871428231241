import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'

import { CustomWindow, Table } from '../../../../../components'
import mapWithRelationship from '../../../../../lib/detectRelationship'
import { selectRelateds } from '../../../../../redux/selectors/relateds'
import {
  formatDocument,
  isCypheredDocument,
  isPending,
  onlyNumbers
} from '../../../../../utils'

const { SearchBar } = Search

export default function AcordosLeniencia ({ data, error, loaded }) {
  const relateds = useSelector(selectRelateds)

  const name = 'bandeiraAmarela.acordosLeniencia'

  if (isPending({ error, loaded })) {
    return (
      <CustomWindow title='Acordos de leniência' name={name}>
        <div />
      </CustomWindow>
    )
  }

  if (error) {
    return (
      <CustomWindow
        title='Acordos de leniência'
        name={name}
        defaultOpen
        showNote
      >
        <div>Ops! Aconteceu um erro ao carregar os Acordos de leniência</div>
      </CustomWindow>
    )
  }

  return (
    <CustomWindow title='Acordos de leniência' name={name} showNote>
      <ToolkitProvider
        keyField='idAcordo'
        search
        columns={[
          { dataField: 'razaoSocial', text: 'Nome' },
          {
            dataField: 'relationship',
            text: 'Grau'
          },
          { dataField: 'document', text: 'CNPJ', searchable: false },
          { dataField: 'rawDocument', text: '', hidden: true },
          { dataField: 'dataInicio', text: 'Data início' },
          { dataField: 'dataFim', text: 'Data fim' },
          { dataField: 'situacao', text: 'Situação' },
          { dataField: 'numeroProcesso', text: 'NUP' },
          { dataField: 'efeitoAcordo', text: 'Efeito do acordo' },
          { dataField: 'termos', text: 'Termos' }
        ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
        data={mapWithRelationship({
          array: data || [],
          documentKey: 'cnpjSancionado',
          nameKey: 'razaoSocial',
          relateds
        }).map(item => {
          return {
            ...item,
            document: isCypheredDocument(item.cnpjSancionado) ? (
              item.cnpjSancionado
            ) : (
              <a
                href={`?documento=${onlyNumbers(item.cnpjSancionado)}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {formatDocument(item.cnpjSancionado)}
              </a>
            )
          }
        })}
      >
        {props => (
          <>
            <div className='row'>
              <div className='col-12 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Table enablePagination {...props.baseProps} />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </CustomWindow>
  )
}
