import { createSelector } from 'reselect'
import {
  BANDEIRA_AMARELA_PROVIDER_NAME,
  BASIC_INFO_PROVIDER_NAME,
  BOANOTA_PROVIDER_NAME,
  ENDIVIDAMENTO_PROVIDER_NAME,
  JURIDICO_PROVIDER_NAME,
  LOGSUL_PROVIDER_NAME,
  MATCH_GRUPO_SOCIETARIO_PROVIDER_NAME,
  OFFSHORE_PROVIDER_NAME,
  PATRIMONIO_PROVIDER_NAME,
  SPREAD_PROVIDER_NAME,
  VISITAS_PROVIDER_NAME,
  DISCREDITING_MEDIA_PROVIDER_NAME,
  RISCO_JURIDICO_PROVIDER_NAME,
  OPERACIONAIS_PROVIDER_NAME
} from '../../config/stepFunction'

import { PROGRESS_TYPES } from '../../lib/progressStatus'
import { StaticScopeMetadata } from '../../lib/scope'

const getProgressStatus = state => state.progressStatus.dataStatus
const getJuridicoTheme = state => state.juridicoTheme
const getDocumento = state => state.globalStep.targetDocument

const getAvailableThemes = () => {
  const dict = {
    [BOANOTA_PROVIDER_NAME]: {
      globalStep: null,
      googleMaps: 'Google Maps'
    },
    [BASIC_INFO_PROVIDER_NAME]: {
      dadosBasicosTheme: 'Dados básicos'
    },
    [ENDIVIDAMENTO_PROVIDER_NAME]: {
      chequeEndividamentoTheme: 'Cheques',
      cndtEndividamentoTheme: 'Cndt',
      comprotEndividamentoTheme: 'Comprot',
      pgfnEndividamentoTheme: 'Pgfn',
      protestoEndividamentoTheme: 'Protesto'
    },
    [BANDEIRA_AMARELA_PROVIDER_NAME]: {
      pepBandeiraAmarela: 'Pep',
      trabalhoEscravoBandeiraAmarela: 'Trabalho escravo',
      acordosLenienciaBandeiraAmarela: 'Acordos de leniência',
      autoInfracaoBandeiraAmarela: 'Infrações',
      cepimBandeiraAmarela: 'Entidades Privadas Sem Fins Lucrativos',
      ofacBandeiraAmarela: 'Ativos Estrangeiros dos EUA',
      termoApreensaoBandeiraAmarela: 'Termo de apreensão',
      termoEmbargoBandeiraAmarela: 'Termo de embargo',
      termoSuspensaoBandeiraAmarela: 'Termo de suspensão',
      cadastroExpulsoesBandeiraAmarela: 'Cadastro de expulsões',
      cnepBandeiraAmarela: 'Empresas Punidas',
      ceisBandeiraAmarela: 'Empresas Inidôneas e Suspensas',
      ceafBandeiraAmarela: 'Expulsões da Administração Federal'
    },
    [OFFSHORE_PROVIDER_NAME]: {
      dataLeaksOffshore: 'Offshore Leaks',
      rdeOffshore: 'Offshore Banco Central'
    },
    [PATRIMONIO_PROVIDER_NAME]: {
      aeronavesPatrimonioTheme: 'Aeronaves',
      sncrPatrimonioTheme: 'Cadastro rural',
      // WARNING: SIGEF não está funcionando mais, por isso foi desabilitado 09/10/2023
      // sigefPatrimonioTheme: 'Ibama',
      ibamaPatrimonioTheme: 'Certificado de Regularidade Ambiental',
      inpiPatrimonioTheme: 'INPI'
    },
    [JURIDICO_PROVIDER_NAME]: {
      juridicoTheme: 'Jurídico'
    },
    [RISCO_JURIDICO_PROVIDER_NAME]: {
      riscoJuridicoTheme: 'Risco Jurídico'
    },
    [SPREAD_PROVIDER_NAME]: {
      spreadTheme: 'Spread'
    },
    [VISITAS_PROVIDER_NAME]: {
      visitasTheme: 'Visitas'
    },
    [LOGSUL_PROVIDER_NAME]: {
      logsulTheme: 'Log Sul'
    },
    [MATCH_GRUPO_SOCIETARIO_PROVIDER_NAME]: {
      matchGrupoSocietarioTheme: 'Match Grupo Societário'
    },
    [OPERACIONAIS_PROVIDER_NAME]: {
      operacionaisTheme: 'Match (ex)funcionários'
    },
    [DISCREDITING_MEDIA_PROVIDER_NAME]: {
      discreditingNewsTheme: 'Notícias Desabonadoras'
    }
  }

  return Object.entries(dict).filter(([key, _]) => {
    return StaticScopeMetadata.getAvailableSteps().includes(key)
  })
}

const filterProgressStatus = progressStatus => {
  const keys = getAvailableThemes()
    .map(([_, values]) => Object.keys(values))
    .flat()
  return Object.fromEntries(
    Object.entries(progressStatus).filter(item => keys.includes(item[0]))
  )
}

export const resolveDictWithLabels = () => {
  return getAvailableThemes()
    .map(([_, value]) => value)
    .reduce((obj, item) => {
      obj = { ...obj, ...item }
      return obj
    }, {})
}

export const selectProgressStatus = createSelector(
  getProgressStatus,
  getDocumento,
  (progressStatus, document) => {
    const dict = resolveDictWithLabels()
    const objectFiltered = filterProgressStatus(progressStatus)

    const grouped = Object.entries(objectFiltered).reduce(
      (obj, [key, value]) => {
        const texts = Array.isArray(dict[key])
          ? dict[key]
          : [dict[key] === null ? null : dict[key] || key]
        if (!value) {
          texts.forEach(text => {
            obj.pending.push({ raw: key, text })
          })
        } else {
          if (
            key === 'globalStep' &&
            [PROGRESS_TYPES.PENDING, null].includes(value)
          ) {
            obj.loaded.push({ raw: key, text: null })
          } else {
            texts.forEach(text => {
              obj[value].push({ raw: key, text })
            })
          }
        }
        return obj
      },
      { loaded: [], error: [], pending: [], partialError: [] }
    )

    const array = grouped.loaded
      .map(item => ({ ...item, status: PROGRESS_TYPES.LOADED }))
      .concat(
        grouped.error.map(item => ({ ...item, status: PROGRESS_TYPES.ERROR }))
      )
      .concat(
        grouped.pending.map(item => ({
          ...item,
          status: PROGRESS_TYPES.PENDING
        }))
      )
      .concat(
        grouped.partialError.map(item => ({
          ...item,
          status: PROGRESS_TYPES.PARTIAL_ERROR
        }))
      )
      .filter(item => item.text !== null)
      .sort((a, b) => a.text.localeCompare(b.text))

    const total = array.length + 1 // +1 for globalStep
    const visible =
      progressStatus.globalStep === PROGRESS_TYPES.PENDING &&
      document &&
      grouped.loaded.length +
        grouped.error.length +
        grouped.partialError.length !==
        total
    const progressLoaded = (grouped.loaded.length / (total || 1)) * 100
    const progressPartialError =
      (grouped.partialError.length / (total || 1)) * 100
    const progressError = (grouped.error.length / (total || 1)) * 100
    const progressPending = (grouped.pending.length / (total || 1)) * 100

    return {
      total,
      visible,
      progressLoaded,
      progressError,
      progressPending,
      progressPartialError,
      array,
      ...grouped
    }
  }
)

export const selectJuridicoProgressStatus = createSelector(
  getJuridicoTheme,
  getDocumento,
  (juridicoTheme, document) => {
    const total = juridicoTheme.relateds.length || 0
    const loaded = (juridicoTheme.relateds || []).filter(
      related => related.status === PROGRESS_TYPES.LOADED
    )
    const error = (juridicoTheme.relateds || []).filter(
      related => related.status === PROGRESS_TYPES.ERROR
    )
    const pending = (juridicoTheme.relateds || []).filter(
      related => related.status === PROGRESS_TYPES.PENDING
    )
    const visible = document && loaded.length + error.length !== total

    const progressLoaded = (loaded.length / (total || 1)) * 100
    const progressError = (error.length / (total || 1)) * 100
    const progressPending = (pending.length / (total || 1)) * 100

    const array = loaded
      .map(item => ({ ...item, status: PROGRESS_TYPES.LOADED }))
      .concat(error.map(item => ({ ...item, status: PROGRESS_TYPES.ERROR })))
      .concat(
        pending.map(item => ({
          ...item,
          status: PROGRESS_TYPES.PENDING
        }))
      )

    return {
      total,
      visible,
      progressLoaded,
      progressError,
      progressPending,
      loaded,
      error,
      pending,
      array
    }
  }
)
