import axios from 'axios'

import { getIdToken, toCamelCase } from '../../utils'

import { SERASA_BASE_URL } from '../../config/env'

export const consultarSerasa = async ({
  targetDocument,
  empresasSelecionadas,
  globalStartTime
}) => {
  const idToken = await getIdToken()

  const { data } = await axios.post(
    `${SERASA_BASE_URL}/serasa-grupo-economico`,
    {
      relateds: empresasSelecionadas.map(
        ({ cnpj, razaoSocial, personType }) => ({
          document: cnpj,
          name: razaoSocial,
          person_type: personType
        })
      ),
      document: targetDocument,
      global_start_time: globalStartTime
    },
    {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  )

  return toCamelCase(data)
}
