import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import * as styles from './style.module.scss'

import { selectMinData } from '../../../../../../redux/selectors/dadosBasicos'
import {
  setScorePldFormSulbrasil,
  setScorePldResultSulBrasil,
  setScorePldTentos
} from '../../../../../../redux/store/sulbrasil'

import API from '../../../../../../services/api'

import { hashObject } from '../../../../../../utils'

import { Loading } from '../../../../../../components'

import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri'

export default function TentoscapScorePld () {
  const [loading, setLoading] = useState(true)
  const [visibleDescriptions, setVisibleDescriptions] = useState({})
  const [visibleDetectedBy, setVisibleDetectedBy] = useState({})
  const { targetDocument } = useSelector(selectMinData)
  const [isGlobalStepLoaded, globalStartTime] = useSelector(state => [
    state.globalStep.loaded,
    state.globalStep.metadata.startedAt
  ])
  const formData = useSelector(
    state => state.sulbrasil.formulario.scorePld.form
  )
  const questionnaires = useSelector(state => state.sulbrasil.formScorePld)
  const result = useSelector(
    state => state.sulbrasil.formulario.scorePld.result
  )
  const dispatch = useDispatch()

  const values = useMemo(() => {
    return (formData || []).reduce((obj, item) => {
      obj[item.id] = {
        value: item.value,
        description: item.description,
        detectedBy: item.detectedBy
      }
      return obj
    }, {})
  }, [formData])

  useEffect(() => {
    if (!isGlobalStepLoaded || !targetDocument) {
      return
    }

    const fetchData = async () => {
      try {
        const form = await API.questionnaires.pld.get({
          globalStartTime,
          document: targetDocument
        })
        const visibleDescriptions = {}
        const visibleDetectedBy = {}

        form.forEach(({ questions }) => {
          questions.forEach(({ id, description, detectedBy }) => {
            visibleDescriptions[id] = description && description.length > 0
            visibleDetectedBy[id] = detectedBy && detectedBy.length > 0
          })
        })
        setVisibleDescriptions(visibleDescriptions)
        setVisibleDetectedBy(visibleDetectedBy)
        dispatch(setScorePldFormSulbrasil(form))
      } catch (err) {
        const message = err.response?.data?.message || err.message
        toast.error(message)
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [isGlobalStepLoaded, targetDocument, globalStartTime, dispatch])

  if (!targetDocument) {
    return <div />
  }

  if (!isGlobalStepLoaded || loading) {
    return <Loading />
  }

  const onChange = nextObj => {
    const nextValues = { ...values, ...nextObj }

    const questionsIds = questionnaires.flatMap(section =>
      section.questions.map(({ id }) => id)
    )

    const formValues = questionsIds.reduce((obj, id) => {
      obj[id] = { value: nextValues[id]?.value }
      return obj
    }, {})

    dispatch(setScorePldTentos({ form: formValues, result: undefined }))
  }

  const onChangeDescription = nextObj => {
    const nextValues = { ...values, ...nextObj }

    const questionsIds = questionnaires.flatMap(section =>
      section.questions.map(({ id }) => id)
    )

    const formValues = questionsIds.reduce((obj, id) => {
      obj[id] = { description: nextValues[id]?.description }
      return obj
    }, {})

    dispatch(setScorePldTentos({ form: formValues }))
  }

  const onChangeDetectedBy = nextObj => {
    const nextValues = { ...values, ...nextObj }

    const questionsIds = questionnaires.flatMap(section =>
      section.questions.map(({ id }) => id)
    )

    const formValues = questionsIds.reduce((obj, id) => {
      obj[id] = { detectedBy: nextValues[id]?.detectedBy }
      return obj
    }, {})

    dispatch(setScorePldTentos({ form: formValues }))
  }

  const handleComputeScore = async () => {
    try {
      setLoading(true)
      const mappedValues = questionnaires
        .map(section =>
          section.questions.map(({ id, answer: defaultAnswer }) => {
            const answer = values?.[id]?.value
            const mappedAnswer =
              id === 'riscoClienteOperacaoAnterior' && !isNaN(parseInt(answer))
                ? parseInt(answer)
                : answer
            return {
              id,
              answer: mappedAnswer ?? defaultAnswer,
              section: section.section
            }
          })
        )
        .flat()

      const result = await API.questionnaires.pld.compute(mappedValues)
      dispatch(setScorePldResultSulBrasil({ result }))
    } catch (err) {
      const message = err.response?.data?.message || err.message
      toast.error(message)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div>
        {questionnaires.map(({ section, title, questions }, index) => {
          const riskResult = result?.[section]
          return (
            <div className={index === 0 ? 'my-4' : 'mt-5 mb-2'} key={section}>
              <div className={styles.section}>{title}</div>
              {questions.map((questionnaire, questionIndex, array) => {
                const name = questionnaire.id
                const questionKey = hashObject({
                  key: 'pld',
                  questionnaire,
                  questionIndex
                })

                const isDescriptionVisible = visibleDescriptions[name]
                const isDetectedByVisible = visibleDetectedBy[name]

                return (
                  <div className='form-group' key={questionKey}>
                    <label>
                      <span className={styles.questionNumber}>
                        {questionIndex + 1})
                      </span>{' '}
                      {questionnaire.question}
                    </label>
                    <div className='d-flex gap-4 mt-2'>
                      {questionnaire.answers.map((answer, answerIndex) => {
                        const id = hashObject({
                          questionKey,
                          answer,
                          questionIndex,
                          answerIndex
                        })
                        return (
                          <div className='form-check' key={id}>
                            <input
                              className='form-check-input'
                              type='radio'
                              name={id}
                              id={id}
                              value={answer.value}
                              onChange={event => {
                                if (onChange) {
                                  onChange({
                                    [name]: { value: event.target.value }
                                  })
                                }
                              }}
                              checked={
                                (values?.[name]?.value ??
                                  questionnaire.answer) === answer.value
                              }
                            />
                            <label className='form-check-label' htmlFor={id}>
                              {answer.text}
                            </label>
                          </div>
                        )
                      })}
                    </div>
                    <div className='mt-1'>
                      <label
                        htmlFor={`${questionKey}-description`}
                        className={styles.description}
                      >
                        {isDescriptionVisible
                          ? 'ocultar descrição'
                          : 'ver descrição'}{' '}
                        <button
                          className='btn btn-sm'
                          onClick={() =>
                            setVisibleDescriptions(prev => ({
                              ...prev,
                              [name]: !isDescriptionVisible
                            }))
                          }
                        >
                          {isDescriptionVisible ? (
                            <RiArrowUpSLine color='#0045c8' />
                          ) : (
                            <RiArrowDownSLine color='#0045c8' />
                          )}
                        </button>
                      </label>
                      {isDescriptionVisible && (
                        <textarea
                          className='form-control'
                          id={`${questionKey}-description`}
                          rows={3}
                          value={
                            values?.[name]?.description ??
                            questionnaire.description
                          }
                          onChange={event => {
                            if (onChangeDescription) {
                              onChangeDescription({
                                [name]: { description: event.target.value }
                              })
                            }
                          }}
                        />
                      )}
                    </div>
                    <div className='mt-1'>
                      <label
                        htmlFor={`${questionKey}-description`}
                        className={styles.description}
                      >
                        {isDetectedByVisible
                          ? 'ocultar informado / detectado por'
                          : 'ver informado / detectado por'}{' '}
                        <button
                          className='btn btn-sm'
                          onClick={() =>
                            setVisibleDetectedBy(prev => ({
                              ...prev,
                              [name]: !isDetectedByVisible
                            }))
                          }
                        >
                          {isDetectedByVisible ? (
                            <RiArrowUpSLine color='#0045c8' />
                          ) : (
                            <RiArrowDownSLine color='#0045c8' />
                          )}
                        </button>
                      </label>
                      {isDetectedByVisible && (
                        <textarea
                          className='form-control'
                          id={`${questionKey}-detectedBy`}
                          rows={1}
                          value={
                            values?.[name]?.detectedBy ??
                            (questionnaire.detectedBy || []).join('. ')
                          }
                          onChange={event => {
                            if (onChangeDetectedBy) {
                              onChangeDetectedBy({
                                [name]: { detectedBy: event.target.value }
                              })
                            }
                          }}
                        />
                      )}
                    </div>
                    <hr
                      className={
                        array.length - 1 === questionIndex ? 'mt-5' : ''
                      }
                    />
                  </div>
                )
              })}
              {riskResult && (
                <div>
                  <div className='h4 text-center'>{riskResult.text}</div>
                  <div className='h6 text-center'>{riskResult.description}</div>
                </div>
              )}
            </div>
          )
        })}
      </div>
      <div className='text-center'>
        <button className='btn btn-primary' onClick={handleComputeScore}>
          CALCULAR SCORE
        </button>
      </div>
    </>
  )
}
