import { Auth } from 'aws-amplify'
import { SCOPE } from '../../config/env'

export const getIdToken = async () => {
  const currentUser = await Auth.currentAuthenticatedUser()

  return currentUser.signInUserSession.idToken.jwtToken
}

export const getAccessToken = async () => {
  const currentUser = await Auth.currentAuthenticatedUser()

  return currentUser.signInUserSession.accessToken.jwtToken
}

export const getUserData = async () => {
  const currentUser = await Auth.currentAuthenticatedUser()
  const email = currentUser.signInUserSession.idToken.payload.email
  const username =
    currentUser.signInUserSession.idToken.payload['cognito:username']
  const identities =
    currentUser.signInUserSession.idToken.payload.identities || []
  const usernameFromIdentities =
    identities.length > 0 ? identities[0].userId : undefined

  const tenant = SCOPE
  const groups =
    currentUser.signInUserSession.idToken.payload['cognito:groups'] || []
  const scope = groups.some(item => item.includes('indrema'))
    ? 'indrema'
    : SCOPE

  return {
    email,
    username: usernameFromIdentities || username,
    tenant,
    groups,
    scope
  }
}
