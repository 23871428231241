import React from 'react'
import { HiCursorClick } from 'react-icons/hi'
import { useSelector } from 'react-redux'

import { CustomWindow } from '..'

import RenderPF from './RenderPF'
import RenderOthers from './RenderOthers'

export default function MaisInformacoesGrafo ({ onClick, selectedNode }) {
  const values = useSelector(state => state.grafo.values)
  const isPF = selectedNode?.data?.document?.length === 11
  const dictKey = `${selectedNode?.data?.document}-${selectedNode?.data?.name}`
  const hasNodeSelected = selectedNode && dictKey && values[dictKey]

  const renderEmpty = () => {
    return (
      <div className='noRecordGrafo'>
        <HiCursorClick size={50} />
        <div>
          clique em algum item do grafo a esquerda para visualizar mais
          informações
        </div>
      </div>
    )
  }

  return (
    <CustomWindow
      title='Informações'
      {...(hasNodeSelected && {
        showNote: true,
        name: `grupoSocietario.informacoes.${(
          selectedNode?.node?.label || ''
        ).replace(/\s/g, '')}`
      })}
    >
      {hasNodeSelected ? (
        isPF ? (
          <RenderPF selectedNode={selectedNode} dictKey={dictKey} />
        ) : (
          <RenderOthers
            selectedNode={selectedNode}
            dictKey={dictKey}
            onClick={onClick}
          />
        )
      ) : (
        renderEmpty()
      )}
    </CustomWindow>
  )
}
