import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import {
  LambdaClient as LambdaClientAWS,
  InvokeCommand
} from '@aws-sdk/client-lambda'

import {
  AWS_COGNITO_REGION,
  AWS_IDENTITY_POOL_ID,
  AWS_IDENTITY_POOL_REGION,
  AWS_USER_POOLS_ID
} from '../config/env'

const LambdaClient = ({
  identityPoolId,
  region,
  lambdaClient,
  credentials,
  idToken
}) => {
  const internalRegion = region || AWS_IDENTITY_POOL_REGION
  const internalIdentityPoolId = identityPoolId || AWS_IDENTITY_POOL_ID

  const internalCredentials =
    credentials ||
    fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: internalRegion }),
      identityPoolId: internalIdentityPoolId,
      logins: {
        [`cognito-idp.${AWS_COGNITO_REGION}.amazonaws.com/${AWS_USER_POOLS_ID}`]: idToken
      }
    })

  const client =
    lambdaClient ||
    new LambdaClientAWS({
      region: 'us-east-1',
      credentials: internalCredentials
    })

  const invokeFunction = async ({ functionName, body }) => {
    const command = new InvokeCommand({
      FunctionName: functionName,
      Payload: Buffer.from(JSON.stringify(body))
    })

    try {
      const data = await client.send(command)
      if (!data.Payload) {
        return data
      }

      const jsonString = Buffer.from(data.Payload).toString('utf8')
      const parsedPayload = JSON.parse(jsonString)

      if (parsedPayload.errorMessage) {
        throw new Error(parsedPayload.errorMessage)
      }

      const statusCode = parsedPayload.statusCode

      if (statusCode && statusCode >= 400) {
        const body = JSON.parse(parsedPayload.body)
        throw new Error(`${body.message}`)
      }

      return parsedPayload
    } catch (err) {
      if (!(err instanceof Error)) {
        return
      }

      const hasMessage = err?.message
      const message = err?.message ?? 'Error performing query'

      if (hasMessage) {
        if (message.includes('Task timed out after')) {
          throw new Error('Timed out, try another data range')
        }
        throw new Error(
          `Please forward the error message to support:\n${message}`
        )
      }
      throw new Error(message)
    }
  }

  return {
    _client: client,
    invokeFunction
  }
}

export class StaticLambdaClient {
  static instance
  static idToken
  static credentials

  static async resolveCredentials () {
    if (!StaticLambdaClient.idToken) {
      throw new Error('Id token is required')
    }

    const credentials = await fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: AWS_IDENTITY_POOL_REGION }),
      identityPoolId: AWS_IDENTITY_POOL_ID,
      logins: {
        [`cognito-idp.${AWS_COGNITO_REGION}.amazonaws.com/${AWS_USER_POOLS_ID}`]: StaticLambdaClient.idToken
      }
    })()

    StaticLambdaClient.credentials = credentials
  }

  static resolveInstance () {
    if (!StaticLambdaClient.idToken) {
      throw new Error('Id token is required')
    }
    StaticLambdaClient.instance = LambdaClient({
      idToken: StaticLambdaClient.idToken,
      credentials: StaticLambdaClient.credentials
    })
  }

  static async getInstance ({ idToken }) {
    const isNewToken =
      !StaticLambdaClient.idToken || StaticLambdaClient.idToken !== idToken
    if (isNewToken) {
      StaticLambdaClient.idToken = idToken
      StaticLambdaClient.credentials = undefined
      StaticLambdaClient.instance = undefined

      await StaticLambdaClient.resolveCredentials()
    }

    const isCredentialsExpired =
      StaticLambdaClient.credentials?.expiration &&
      StaticLambdaClient.credentials.expiration.getTime() <= Date.now()
    if (isCredentialsExpired) {
      await StaticLambdaClient.resolveCredentials()
    }

    const isNewInstanceRequired = isNewToken || isCredentialsExpired

    if (!StaticLambdaClient.instance || isNewInstanceRequired) {
      StaticLambdaClient.resolveInstance()
    }

    return StaticLambdaClient.instance
  }
}

export default LambdaClient
