export const TYPES = {
  SET_USER: 'AUTENTICACAO/SET_USER'
}

const INITIAL_STATE = {
  user: undefined
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TYPES.SET_USER:
      return { ...state, user: action.payload }
    default:
      return state
  }
}
