import React, { useCallback } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'

import Loading from './Loading'

import { GOOGLE_AUTHORIZATION_KEY } from '../config/env'

const AREA_TYPE = {
  IMOVEL: {
    id: 'imovel',
    label: 'Área do Imóvel',
    color: '#000000'
  }
}

const DynamicGoogleMaps = React.memo(
  ({ geoJson: geometry, mapContainerStyle }) => {
    const onLoad = useCallback(
      map => {
        if (!geometry) {
          return
        }

        const geoJson = {
          type: 'Feature',
          geometry,
          properties: { name: AREA_TYPE.IMOVEL.id }
        }

        map.data.addGeoJson(geoJson)
        const bounds = new window.google.maps.LatLngBounds()

        map.data.forEach(function (feature) {
          const geo = feature.getGeometry()
          if (!geo) {
            return
          }

          geo.forEachLatLng(function (LatLng) {
            bounds.extend(LatLng)
          })
        })

        map.fitBounds(bounds)
        map.setZoom(10)

        map.data.setStyle(feature => {
          const name = feature.getProperty('name')

          const color = Object.values(AREA_TYPE).find(item => item.id === name)
            ?.color
          if (!color) {
            return {
              strokeWeight: 1,
              fillOpacity: 1
            }
          }

          return {
            fillColor: color,
            strokeWeight: 1,
            fillOpacity: name === AREA_TYPE.IMOVEL.id ? 0.5 : 1
          }
        })
      },
      [geometry]
    )

    const { isLoaded, loadError } = useJsApiLoader({
      googleMapsApiKey: GOOGLE_AUTHORIZATION_KEY
    })

    if (loadError) {
      console.error(loadError)
      return (
        <div>
          <p>Map cannot be loaded right now, sorry.</p>
        </div>
      )
    }

    if (!isLoaded) {
      return <Loading />
    }

    return (
      <div>
        <GoogleMap
          onLoad={onLoad}
          mapContainerStyle={mapContainerStyle}
          options={{
            zoomControlOptions: {
              position: window.google.maps.ControlPosition.RIGHT_CENTER
            }
          }}
        />
      </div>
    )
  }
)

DynamicGoogleMaps.displayName = 'DynamicGoogleMaps'

export default DynamicGoogleMaps
