import axios from 'axios'

import { COMMENTS_BASE_URL } from '../../config/env'
import {
  getCurrentUserAndEmail,
  getEmail,
  getIdToken,
  toCamelCase
} from '../../utils'

const nEntries = 1

export const saveComment = async ({ texto, documento, campo }) => {
  const [token, user] = await Promise.all([
    getIdToken(),
    getCurrentUserAndEmail()
  ])

  const { data } = await axios.put(
    `${COMMENTS_BASE_URL}/putField`,
    {
      value: texto
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-username-id': user.email,
        'x-doc-type': 'bnt_analise',
        'x-scope-id': 'boanota'
      },
      params: {
        fields: campo,
        key: documento
      }
    }
  )

  const formattedData = toCamelCase(data)
  return formattedData
}

export const getComment = async ({ documento, campo }) => {
  const [token, user] = await Promise.all([
    getIdToken(),
    getCurrentUserAndEmail()
  ])

  const { data } = await axios.get(`${COMMENTS_BASE_URL}/getField`, {
    params: {
      fields: campo,
      key: documento,
      n_entries: nEntries
    },
    headers: {
      Authorization: `Bearer ${token}`,
      'x-username-id': user.email,
      'x-doc-type': 'bnt_analise',
      'x-scope-id': 'boanota'
    }
  })

  const formattedData = toCamelCase(data)
  return formattedData
}

export const lockComment = async ({ documento, campo }) => {
  const [token, user] = await Promise.all([
    getIdToken(),
    getCurrentUserAndEmail()
  ])

  const { data } = await axios.get(`${COMMENTS_BASE_URL}/lockField`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'x-username-id': user.email,
      'x-doc-type': 'bnt_analise',
      'x-scope-id': 'boanota'
    },
    params: {
      fields: campo,
      key: documento
    }
  })

  const formattedData = toCamelCase(data)
  return formattedData
}

export const unlockComment = async ({ documento, campo }) => {
  const [token, user] = await Promise.all([
    getIdToken(),
    getCurrentUserAndEmail()
  ])

  const { data } = await axios.get(`${COMMENTS_BASE_URL}/unlockField`, {
    params: {
      fields: campo,
      key: documento
    },
    headers: {
      Authorization: `Bearer ${token}`,
      'x-username-id': user.email,
      'x-doc-type': 'bnt_analise',
      'x-scope-id': 'boanota'
    }
  })

  const formattedData = toCamelCase(data)
  return formattedData
}

export const moveComment = async ({ document, field, newField }) => {
  const [token, email] = await Promise.all([getIdToken(), getEmail()])

  const { data } = await axios
    .get(`${COMMENTS_BASE_URL}/moveField`, {
      params: {
        fields: field,
        key: document,
        new_fields: newField
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'x-scope-id': 'boanota',
        'x-doc-type': 'relatorio',
        'x-username-id': email
      }
    })
    .catch(err => {
      const errorMessage = err.response.data.message || err.message

      if (errorMessage === 'blob not found.') {
        return { data: { message: 'blob not found.' } }
      }

      throw err
    })

  return toCamelCase(data)
}
