import { call, select } from 'redux-saga/effects'

import RecentSearches from '../../lib/recentSearches'
import { fetchDadosBasicosFromS3 } from '../../lib/s3Theme'

export function * putLocalHistory (action) {
  const globalStep = yield select(state => state.globalStep)
  const id = globalStep.metadata.executionArn

  try {
    const payload = yield select(state => state.dadosBasicosTheme)
    const dadosBasicos = yield call(fetchDadosBasicosFromS3, { payload })
    const name =
      dadosBasicos.data.dadosBasicos.razaoSocial ||
      dadosBasicos.data.dadosBasicos.nome

    RecentSearches.put({
      id,
      document: globalStep.targetDocument,
      name: name
    })
  } catch (err) {
    console.error(err)
    RecentSearches.put({
      id,
      document: globalStep.targetDocument
    })
  }
}
