import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useInterval } from '../utils'
import RecentSearches from './recentSearches'

import { getUserData } from '../services/aws'

const INTERVAL_TO_UPDATE_HISTORY = 1 * 60 * 1000 // 1 minutes

export const useSearchHistory = () => {
  const currentUser = useSelector(state => state.autenticacao.user)
  const [loading, setLoading] = useState(false)
  const [allSearches, setAllSearches] = useState(
    RecentSearches.getRecentSearches()
  )
  const [mySearches, setMySearches] = useState([])
  const [updatedAt, setUpdatedAt] = useState(
    RecentSearches.getUpdatedAtFromLocalStorage()
  )

  useInterval(() => {
    setLoading(true)
    RecentSearches.updateRecentSearches()
      .then(() => getUserData())
      .then(user => {
        setAllSearches(RecentSearches.getRecentSearches())
        setMySearches(
          RecentSearches.getMyRecentSearches({ username: user.username })
        )
        setUpdatedAt(RecentSearches.getUpdatedAtFromLocalStorage())
      })
      .catch(err => {
        console.error(err)
        toast.error('Erro ao atualizar as buscas recentes', {
          position: toast.POSITION.BOTTOM_RIGHT,
          toastId: 'recent-searches-error'
        })
      })
      .finally(() => setLoading(false))
  }, INTERVAL_TO_UPDATE_HISTORY)

  useEffect(() => {
    const run = async () => {
      const { userId, scope } = RecentSearches.getMetadata()
      const userData = await getUserData()
      const userFromLocalStorage = userData.username
      const scopeFromLocalStorage = userData.scope

      if (
        userFromLocalStorage &&
        (userId !== userFromLocalStorage?.toString() ||
          scope !== scopeFromLocalStorage)
      ) {
        RecentSearches.resetLocalStorage()
        RecentSearches.updateRecentSearches()
          .then(() => getUserData())
          .then(user => {
            setAllSearches(RecentSearches.getRecentSearches())
            setMySearches(
              RecentSearches.getMyRecentSearches({ username: user.username })
            )
            setUpdatedAt(RecentSearches.getUpdatedAtFromLocalStorage())
          })
          .catch(err => {
            console.error(err)
            toast.error('Erro ao atualizar as buscas recentes', {
              position: toast.POSITION.BOTTOM_RIGHT,
              toastId: 'recent-searches-error'
            })
          })
      }
    }
    run().catch(err => console.error(err))
  }, [currentUser])

  return {
    loading,
    allSearches,
    mySearches,
    updatedAt
  }
}
