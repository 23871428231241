import React from 'react'
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs'
import ReactTooltip from 'react-tooltip'
import 'react-step-progress-bar/styles.css'
import { ProgressBar } from 'react-step-progress-bar'

import { factoryDate, formatDate } from './../utils'

const ARRAY_PAGE_SIZE = 5

const arrayPaginate = (pageNumber, timeline) => {
  return (timeline || []).slice(
    (pageNumber - 1) * ARRAY_PAGE_SIZE,
    pageNumber * ARRAY_PAGE_SIZE
  )
}

const DetailsTimeline = props => {
  const { timeline } = props
  const [arrayPageActual, setArrayPageActual] = React.useState(1)
  const [arrayTotalPages, setArrayTotalPages] = React.useState([])
  const [arrayPorcentage, setArrayPorcentage] = React.useState(0)

  React.useEffect(() => {
    arrayPaginate(1, timeline)
  }, [timeline])

  React.useEffect(() => {
    const totalPagesTemp = parseFloat(
      ((timeline || []).length - 1) / ARRAY_PAGE_SIZE
    ).toFixed(0)
    const arrayEmpty = new Array(parseInt(totalPagesTemp)).fill(0)
    setArrayTotalPages(old => arrayEmpty)

    const total = parseFloat((1 / arrayEmpty.length) * 100).toFixed(0)
    setArrayPorcentage(total)
  }, [timeline])

  React.useEffect(() => {
    // %
    const total = parseFloat(
      (arrayPageActual / arrayTotalPages.length) * 100
    ).toFixed(0)
    if (arrayPageActual === arrayTotalPages.length) {
      setArrayPorcentage(100)
    } else if (arrayPageActual === 1) {
      setArrayPorcentage(0)
    } else {
      setArrayPorcentage(total)
    }
  }, [arrayPageActual, arrayTotalPages])

  const paginateGo = type => {
    if (type === 'foward') {
      if (arrayPageActual <= arrayTotalPages.length - 1) {
        setArrayPageActual(arrayPageActual + 1)
      }
    } else {
      if (arrayPageActual > 0) {
        setArrayPageActual(arrayPageActual - 1)
      }
    }
    arrayPaginate(arrayPageActual, timeline)

    // %
    const total = parseFloat(
      (arrayPageActual / arrayTotalPages.length) * 100
    ).toFixed(0)
    if (arrayPageActual === arrayTotalPages.length) {
      setArrayPorcentage(100)
    } else if (arrayPageActual === 1) {
      setArrayPorcentage(0)
    } else {
      setArrayPorcentage(total)
    }
  }

  return (
    <div className='row justify-content-start timeline-base col-12 no-gutters'>
      <ReactTooltip />

      <div className='timeline-content col-12 row justify-content-start no-gutters'>
        {arrayPaginate(arrayPageActual, timeline).map((item, key) => {
          return (
            <div className='timeline-item col-auto' key={key}>
              <div className='timeline-container'>
                <div className='timeline-desc'>{item.content}</div>
                <div className='timeline-date'>
                  {formatDate(factoryDate(item.date))}
                </div>
              </div>
              <div className='timeline-line'>
                {/* <AiOutlineDash size={20} /> */}
                {/* <span className="timeline-circle" /> */}
                {/* <AiOutlineDash size={20} /> */}
              </div>
            </div>
          )
        })}
      </div>

      <div className='timeline-toolbar col-12 row justify-content-center no-gutters'>
        <div className='col-10' style={{ marginTop: '30px' }}>
          <ProgressBar
            percent={arrayPorcentage}
            filledBackground='linear-gradient(to right, #00b07d, #9aca3c)'
          />
        </div>
        <div className='col-2 text-center' style={{ marginTop: '10px' }}>
          <div className='btn-group' role='group'>
            <button
              className='btn btn-outline-dark btnTimeline'
              data-tip='Voltar Andamentos'
              onClick={() => paginateGo('back')}
              disabled={arrayPageActual === 1}
            >
              <BsArrowLeftShort size={20} />
            </button>

            <button
              className='btn btn-outline-dark btnTimeline'
              data-tip='Avançar Andamentos'
              onClick={() => paginateGo('foward')}
              disabled={arrayPageActual === arrayTotalPages.length}
            >
              <BsArrowRightShort size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsTimeline
